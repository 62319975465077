import React, { useState ,useEffect } from "react";
import axios from "axios";
import { IoManSharp } from "react-icons/io5";

function TechniciensTab() {
  const apiHost = process.env.REACT_APP_API_HOST;

  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true); // State to manage loading status

  useEffect(() => {
	const fetchUsers = async () => {
		try {
		  const response = await axios.get(`${apiHost}/users`);
		  setUsers(response.data); // Assuming the API returns an array of users
		  setIsLoadingUsers(false); // Set isLoading to false after data is fetched
		} catch (error) {
		  console.error('Error fetching users:', error);
		  setIsLoadingUsers(false); // Set isLoading to false on error as well
		}
	  };

	fetchUsers();
  }, []);
  return (
 
	<div
	className="tab-pane fade"
	id="kt_table_widget_5_tab_2"
  >
	{/*begin::Table container*/}
	<div className="table-responsive">
	  {/*begin::Table*/}
	  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
		{/*begin::Table head*/}
		<thead>
		  <tr className="border-0">
			<th className="p-0 w-50px" />
			<th className="p-0 min-w-150px" />
			<th className="p-0 min-w-140px" />
			<th className="p-0 min-w-110px" />
			<th className="p-0 min-w-50px" />
		  </tr>
		</thead>
		{/*end::Table head*/}
		{/*begin::Table body*/}
		<tbody>
				{isLoadingUsers ? (
				<tr>
                <td colSpan="5" className="text-center">Loading...</td>
              </tr>
			) : (
				users.map(user => (
				<tr>
					<td>
					<div className="symbol symbol-45px me-2">
						<span className="symbol-label">
						<IoManSharp  width={91}/>
						</span>
					</div>
					</td>
					<td>
					<a
						href="#"
						className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6"
					>
						Technicien {user.name}
					</a>
					<span className="text-muted fw-semibold d-block">
						{user.email}
					</span>
					</td>
					<td className="text-end text-muted fw-bold">
					React, HTML
					</td>
					<td className="text-end">
					<span className="badge badge-light-success">
						En Intervention
					</span>
					</td>
					<td className="text-end">
					<a
						href="#"
						className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
					>
						<i className="ki-duotone ki-arrow-right fs-2">
						<span className="path1" />
						<span className="path2" />
						</i>
					</a>
					</td>
				</tr>
						))
					)}
		   
		   
		  
	
		</tbody>
		{/*end::Table body*/}
	  </table>
	</div>
	{/*end::Table*/}
  </div>
  );
}

export default TechniciensTab;
