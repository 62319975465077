import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [values, setValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [error, setError] = useState(null);
  const [selectedPhrase, setSelectedPhrase] = useState('You selected: ');
  const apiHost = process.env.REACT_APP_API_HOST;


  useEffect(() => {
    axios.get(`${apiHost}/referentiels-intervention`)
      .then((response) => {
        setValues({ 0: response.data.referentiel_parent.map(item => ({ value: item.id, label: getDisplayValue(item) })) });
      }).catch((error) => {
        console.error(error);
        setError('Failed to fetch data from the API.');
      });
  }, []);

  const handleChangeReferentiel = (level, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    let newSelectedValues = { ...selectedValues, [level]: value };
    
    // Réinitialiser les valeurs sélectionnées pour les niveaux suivants
    Object.keys(newSelectedValues).forEach(key => {
      if (parseInt(key) > level) {
        delete newSelectedValues[key];
      }
    });

    setSelectedValues(newSelectedValues);

    // Réinitialiser les options pour les niveaux suivants
    let newValues = { ...values };
    Object.keys(newValues).forEach(key => {
      if (parseInt(key) > level) {
        delete newValues[key];
      }
    });

    setValues(newValues);

    // Construire l'URL en fonction des sélections précédentes
    let apiUrl = `${apiHost}/referentiels-intervention`;
    for (let i = 0; i <= level; i++) {
      if (newSelectedValues[i]) {
        apiUrl += `/${newSelectedValues[i]}`;
      }
    }

    axios.get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          const nextLevelData = level % 2 === 0 ? response.data : response.data.enfants;
          if (!nextLevelData.length && level % 2 !== 0) return;
          setValues({ ...newValues, [level + 1]: nextLevelData.map(item => ({ value: item.id, label: getDisplayValue(item) })) });
        } else {
          console.log(`Received non-200 status code: ${response.status}`);
        }
      }).catch((error) => {
        console.error(error);
        setError('Failed to fetch data from the API.');
      });

    // Mettre à jour la phrase sélectionnée
    updateSelectedPhrase(newSelectedValues);
  };

  const updateSelectedPhrase = (selectedValues) => {
    const selectedLabels = Object.keys(selectedValues).map(level => {
      const selectedValue = selectedValues[level];
      const selectedOption = values[level].find(option => option.value === selectedValue);
      return selectedOption ? selectedOption.label : '';
    }).filter(label => label !== '');
    setSelectedPhrase(`You selected: ${selectedLabels.join(', ')}`);
  }

  const getDisplayValue = (value) => {
    return value.valeur || value.nom || 'Unknown';
  }

  return (
    <div className="App container mt-5 col-md-6">
      <h1 className='mb-3'>Referentiels Intervention</h1>
      {error && <div className="alert alert-danger">{error}</div>}

      {Object.keys(values).map((level) => (
        <div key={level} className='mb-3'>
          <Select
            value={selectedValues[level] ? { value: selectedValues[level], label: values[level].find(option => option.value === selectedValues[level])?.label } : null}
            onChange={(selectedOption) => handleChangeReferentiel(parseInt(level), selectedOption)}
            options={values[level]}
            isClearable
          />
          <div className="form-text">
            {level % 2 === 0 ? 'Referentiel' : 'Valeur référentiel'}
          </div>
        </div>
      ))}

      <p className="mt-3">{selectedPhrase}</p>
    </div>
  );
}

export default App;
