import React, { useEffect, useState } from "react";
import Header from "../../../Layout/menu/Header";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

function InterventionType() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [interventionType, setInterventionType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Searchstring, setSearchstring] = useState("");
  const [filteredInterventionType, setFilteredInterventionType] = useState();
  const [categorie, setCategorie] = useState([]);
  const [formulaire, setFormulaire] = useState([]);
  const [errorCode, setErrorCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formInterventionType, setFormInterventionType] = useState({
    name: "",
    code: "",
    unit: "Heures/Minutes",
    duration: "",
    with_appointment: 0,
    self_planning: 0,
    form_id: null,
    category_id: null,
    travel_time: "Inclus dans la durée de intervention",
    description: "",
    visibility: "Tous les utilisateurs",
  });

  const isFormValid = () => {
    return (
      formInterventionType.name &&
      formInterventionType.code &&
      formInterventionType.duration &&
      formInterventionType.form_id &&
      formInterventionType.category_id
    );
  };

  useEffect(() => {
    setFilteredInterventionType(
      interventionType.filter((type) =>
        type.name.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, interventionType]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  useEffect(() => {
    const fetchInterventionTypes = axios.get(`${apiHost}/intervention-types/`);
    const fetchCategories = axios.get(`${apiHost}/categories-referentiels/`);
    const fetchForms = axios.get(`${apiHost}/forms/`);

    Promise.all([fetchInterventionTypes, fetchCategories, fetchForms])
      .then((responses) => {
        const [interventionTypesResponse, categoriesResponse, formsResponse] =
          responses;
        setInterventionType(interventionTypesResponse.data);
        setCategorie(categoriesResponse.data);
        setFormulaire(formsResponse.data.data);
      })
      .catch((error) => {
        setError(error);
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      name: "Nom",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Unité",
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: "Formulaire",
      selector: (row) => row.form?.name,
      sortable: true,
    },
    {
      name: "Catégorie",
      selector: (row) => row.category.name,
      sortable: true,
    },
    {
      name: "Date de création",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link
          variant="link"
          to={`/type-intervention/edit/${row.id}`}

          style={{
            color: "#007bff",
          }}
        >
          <FaEdit
            style={{
              fontSize: "24px",
            }}
          />
        </Link>
      ),
    },
  ];


  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Liste des types d'intervention
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="d-grid gap-2 col-6 mx-auto">
                  <Link to="/type-intervention/create" className="btn btn-primary">
                      Nouveau
                    </Link>
                  </div>
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Liste des types d'intervention
                  </h1>
                  <div className="card-body pt-0">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src="/assets/loading.svg" alt="Loading" />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <DataTable
                            columns={columns}
                            data={filteredInterventionType}
                            pagination
                            paginationPerPage={10} // Set the number of rows per page
                            customStyles={customStyles}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
      
    </div>
  );
}

export default InterventionType;
