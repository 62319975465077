import React, { useState } from "react";
import { BiBrush, BiUser } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { SlOptionsVertical } from "react-icons/sl";
import { Link, useLocation } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";

function Header() {
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const isActive = (path) => location.pathname === path;

  // State to manage the visibility of menu items
  const [visibleMenuItems, setVisibleMenuItems] = useState({
    monitoring: true,
    interventions: true,
    
    referentiel: true,
    planning:true,
    cartographie:true,
    geolocalisation:true
  });

  // Handler to toggle visibility of menu items
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setVisibleMenuItems((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <div
      id="kt_header"
      className="header align-items-stretch sticky-header"
      style={{ height: "40px", background: "black", position: "sticky", top: 0, zIndex: 1000 }}
      data-kt-sticky="true"
      data-kt-sticky-name="header"
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"

    >
      <div
        className="container-xxl d-flex align-items-center"
        style={{ maxWidth: "none" }}
      >
        <div
          className="d-flex topbar align-items-center d-lg-none ms-n2 me-3"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <i className="ki-duotone ki-abstract-14 fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>
        </div>
        <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
          <a href="/">
            <img
              alt="Logo"
              src="assets/media/logos/SFSlogo.png"
              className="logo-default h-25px"
            />
            <img
              alt="Logo"
              src="assets/media/logos/SFSlogo.png"
              className="logo-sticky h-25px"
            />
          </a>
        </div>
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <div className="d-flex align-items-stretch" id="kt_header_nav">
            <div
              className="header-menu align-items-stretch"
              data-kt-drawer="true"
              data-kt-drawer-name="header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
            >
              <div
                className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-500 fw-semibold my-5 my-lg-0 align-items-stretch px-2 px-lg-0"
                id="#kt_header_menu"
                data-kt-menu="true"
              >
                
                {visibleMenuItems.monitoring && (
                  <Link
                    to="/"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-placement="bottom-start"
                    className={`menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2 ${
                      isActive("/") ? "here show" : ""
                    }`}
                  >
                    <span className="menu-link py-3">
                      <span className="menu-title">Monitoring</span>
                    </span>
                  </Link>
                )}
                
                {visibleMenuItems.interventions && (
  <div
    className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention dropdown ${
      isActive("/interventions") ? "here show" : ""
    }`}
    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
    data-kt-menu-placement="bottom-start"
  >
    <Link
      to="/interventions"
      className="menu-link py-3 dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span className="menu-title">Interventions</span>
    </Link>
    <div className="dropdown-menu bg-amber-a100">
    <Link to="/interventions" className="dropdown-item">
        Interventions
      </Link>
      <Link to="/search-intervention" className="dropdown-item">
        Recherche avancée
      </Link>
      <Link to="#" className="dropdown-item">
        Tournées
      </Link>
      <Link to="#" className="dropdown-item">
        Récurrences
      </Link>
      <Link to="#" className="dropdown-item">
        SmartScheduler
      </Link>
      <Link to="#" className="dropdown-item">
        Export
      </Link>
      <Link to="#" className="dropdown-item">
        Analytics
      </Link>
    </div>
  </div>
)}

{visibleMenuItems.planning && (
  <div
    className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention dropdown ${
      isActive("/planning") ? "here show" : ""
    }`}
    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
    data-kt-menu-placement="bottom-start"
  >
    <Link
      to="/planning"
      className="menu-link py-3 dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span className="menu-title">Planning</span>
    </Link>
    <div className="dropdown-menu bg-amber-a100">
      <Link to="#" className="dropdown-item">
        Planing
      </Link>
      <Link to="/useractivites" className="dropdown-item">
        Suivi des activités
      </Link>
    </div>
  </div>
)}

{visibleMenuItems.cartographie && (
  <div
    className={`menu-item ${
      isActive("/cartographie") ? "here show" : ""
    }`}
  >
    <Link to="/cartographie" className="menu-link py-3">
      <span className="menu-title">Cartographie</span>
    </Link>
  </div>
)}
{visibleMenuItems.geolocalisation && (
  <div
    className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention dropdown ${
      isActive("/geolocalisation") ? "here show" : ""
    }`}
    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
    data-kt-menu-placement="bottom-start"
  >
    <Link
      to="/geolocalisation"
      className="menu-link py-3 dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span className="menu-title">Géolocalisation</span>
    </Link>
    <div className="dropdown-menu bg-amber-a100">
      <Link to="/dernierespositions" className="dropdown-item">
        Dernières positions
      </Link>
    </div>
  </div>
)}

              
               
                {visibleMenuItems.referentiel && (
                  <Link
                    to="/categories-referentiel"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-placement="bottom-start"
                    className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 ${
                      isActive("/referentiellist") ? "here show" : ""
                    }`}
                  >
                    <span className="menu-link py-3">
                      <span className="menu-title">Réferentiel</span>
                    </span>
                  </Link>
                )}
        
              </div>
            </div>
          </div>
          <div className="topbar d-flex align-items-stretch flex-shrink-0">
            <div className="d-flex align-items-center ms-1 ms-lg-3">
            <div className="d-flex align-items-center ms-1 ms-lg-3">
              <div
                className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px dropdown"
                id="kt_activities_toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ki-duotone ki-chart-simple fs-1">
                <IoMdMenu />

                </i>
              </div>
              <ul className="dropdown-menu dropdown-menu-end">
              <div className="px-3">
              {Object.entries(visibleMenuItems).map(([key, isVisible]) => (
          <div className="px-3" key={key}>
            <label className="d-flex align-items-center">
              <input
                type="checkbox"
                name={key}
                checked={isVisible}
                onChange={handleCheckboxChange}
              />
              <span className="ms-2">{key.charAt(0).toUpperCase() + key.slice(1)}</span>
            </label>
          </div>
        ))}
                                </div>
                
              </ul>
            </div>
              

             
            </div>
            <div className="d-flex align-items-center ms-1 ms-lg-3">
  <div
    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px dropdown"
    id="kt_activities_toggle"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="ki-duotone ki-chart-simple fs-1">
      <CiSettings />
    </i>
  </div>
  <ul className="dropdown-menu dropdown-menu-end">
    <li>
      <Link className="dropdown-item" to={"/activities"}>Type d'activités</Link>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Type d'équipements</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Indicateurs</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Compétances technicien</a>
    </li>
    <li>
    <Link className="dropdown-item" to={"/formulaires"}>Formulaire</Link>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Liste de références</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Champs personnalisés</a>
    </li>
    <li>
    <Link className="dropdown-item" to={"/type-intervention"}>Types d'interventions</Link>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Scénarios</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Types de notes</a>
    </li>
    
  </ul>
</div>

<div className="d-flex align-items-center ms-1 ms-lg-3">
  <div
    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px dropdown"
    id="kt_activities_toggle"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="ki-duotone ki-chart-simple fs-1">
      <BiUser />
    </i>
  </div>
  <ul className="dropdown-menu dropdown-menu-end">
    <li>
      <a className="dropdown-item" href="#action1">Agences</a>
    </li>
    <li>
    <Link className="dropdown-item" to={"/techniciens"}>Techniciens </Link>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Versions mobiles</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Profils</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Utilisateurs</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Régles d'import</a>
    </li>
    <li>
      <a className="dropdown-item" href="#action1">Abonnements</a>
    </li>

    
  </ul>
</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
