import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { CirclePicker, CompactPicker } from "react-color";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa";

function CreateTechnicien() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formPrenom, setFormPrenom] = useState("");
  const [formNom, setFormNom] = useState("");
  const [formCode, setFormCode] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formTelephone, setFormTelephone] = useState("");
  const [formAdresse, setFormAdresse] = useState("");
  const [formCodePostal, setFormCodePostal] = useState("");
  const [formVille, setFormVille] = useState("");
  const [formLatitude, setFormLatitude] = useState("");
  const [formLongitude, setFormLongitude] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPasswordConfirmation, setFormPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiHost}/users`, {
        prenom: formPrenom,
        nom: formNom,
        code: formCode,
        email: formEmail,
        telephone: formTelephone,
        adresse: formAdresse,
        code_postal: formCodePostal,
        ville: formVille,
        latitude: formLatitude,
        longitude: formLongitude,
        password: formPassword,
        password_confirmation: formPasswordConfirmation,
      });
      // Success logic (e.g., alert or navigation)
      // alert("Technicien ajouté avec succès.");
      navigate('/techniciens'); // Redirect after successful creation
    } catch (error) {
      console.error("Failed to add user:", error);
      // Set errors based on the response from the server
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        // Handle general error
        alert("Erreur lors de l'ajout du technicien.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {/*begin::Header */}
          <Header />
          {/*end::Header */}
          <div className="Customcontainer bg-white">
            <div
              className="row bg-white"
              style={{ flex: 1, minHeight: "91.1vh" }}
            >
              <div
                className="col-2 p-5 "
                style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
              >
                {/* Contenu de la colonne de gauche */}
                <p
                  className="etech-sidebar-section"
                  style={{
                    color: "#3a3838",
                    fontWeight: "700",
                    padding: ".25em 0",
                  }}
                >
                  Rechercher un type d'activité
                </p>
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-subscription-table-filter="search"
                    className="form-control form-control-solid  ps-12 mb-2 border"
                    placeholder="Recherche ..."
                    onChange={"handleInputChange"}
                    disabled
                  />
                </div>
                <div class="d-grid gap-2 col-6 mx-auto">
                  <Link to="/creatactivity" className="btn btn-primary">
                    Rechercher
                  </Link>
                </div>{" "}
              </div>
              <div className="col-10">
                {/* Contenu de la colonne de droite */}
                <h1
                  className="d-flex  fw-bold my-1 fs-3 p-4"
                  style={{ fontSize: "1.5em", fontWeight: "400" }}
                >
                  Nouveau Technicien
                </h1>
                <div className="card-body pt-0">
                  {/*begin::Post */}
                  <div className="content flex-row-fluid" id="kt_content">
                    {/*begin::Careers - Apply */}
                    <div className="card">
                      {/*begin::Body */}
                      <div className="card-body p-3">
                        {/*begin::Hero */}

                        <div className="d-flex flex-column flex-lg-row mb-17">
                          {/*begin::Content */}
                          <div className="flex-lg-row-fluid me-0 ">
                            {/*begin::Form */}

                            <form
                              action="m-0"
                              className="form mb-15"
                              method="post"
                              id="kt_careers_form"
                              onSubmit={handleFormSubmit}
                            >
                             {/* Nom Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Nom
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="text"
      value={formNom}
      onChange={(e) => setFormNom(e.target.value)}
      required
      placeholder="Nom"
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.nom ? 'is-invalid' : ''}`}
    />
    {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}
  </div>
</div>

{/* Prénom Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Prénom
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="text"
      placeholder=""
      required
      value={formPrenom}
      onChange={(e) => setFormPrenom(e.target.value)}
      name="Prénom"
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.prenom ? 'is-invalid' : ''}`}
    />
    {errors.prenom && <div className="invalid-feedback">{errors.prenom}</div>}
  </div>
</div>

{/* Code Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Code
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="text"
      placeholder=""
      required
      value={formCode}
      onChange={(e) => setFormCode(e.target.value)}
      name="Code"
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.code ? 'is-invalid' : ''}`}
    />
    {errors.code && <div className="invalid-feedback">{errors.code}</div>}
  </div>
</div>

{/* Email Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Email
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="email"
      placeholder=""
      required
      name="Email"
      value={formEmail}
      onChange={(e) => setFormEmail(e.target.value)}
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
    />
    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
  </div>
</div>

{/* Téléphone Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Téléphone
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="tel"
      placeholder=""
      required
      value={formTelephone}
      onChange={(e) => setFormTelephone(e.target.value)}
      name="Téléphone"
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
    />
    {errors.telephone && <div className="invalid-feedback">{errors.telephone}</div>}
  </div>
</div>

{/* Adresse Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Adresse
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <textarea
      placeholder=""
      required
      value={formAdresse}
      onChange={(e) => setFormAdresse(e.target.value)}
      name="Adresse"
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "80px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.adresse ? 'is-invalid' : ''}`}
    ></textarea>
    {errors.adresse && <div className="invalid-feedback">{errors.adresse}</div>}
  </div>
</div>

{/* Code postal Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Code postal
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="text"
      placeholder=""
      required
      value={formCodePostal}
      onChange={(e) => setFormCodePostal(e.target.value)}
      name="Code postal"
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.code_postal ? 'is-invalid' : ''}`}
    />
    {errors.code_postal && <div className="invalid-feedback">{errors.code_postal}</div>}
  </div>
</div>

{/* Ville Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Ville
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="text"
      placeholder=""
      required
      name="Ville"
      value={formVille}
      onChange={(e) => setFormVille(e.target.value)}
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.ville ? 'is-invalid' : ''}`}
    />
    {errors.ville && <div className="invalid-feedback">{errors.ville}</div>}
  </div>
</div>

{/* Latitude Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Latitude/Longitude
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <div className="d-flex">
      <input
        type="text"
        placeholder="Lat"
        required
        name="Latitude"
        value={formLatitude}
        onChange={(e) => setFormLatitude(e.target.value)}
        style={{
          width: "100%",
          maxWidth: "80px",
          height: "27px",
          marginLeft: "5px",
        }}
        className={`form-control ${errors.latitude ? 'is-invalid' : ''}`}
      />
      <input
        type="text"
        placeholder="Lon"
        required
        name="Longitude"
        value={formLongitude}
        onChange={(e) => setFormLongitude(e.target.value)}
        style={{
          width: "100%",
          maxWidth: "80px",
          height: "27px",
          marginLeft: "10px",
        }}
        className={`form-control ${errors.longitude ? 'is-invalid' : ''}`}
      />
    </div>
    {errors.latitude && <div className="invalid-feedback">{errors.latitude}</div>}
    {errors.longitude && <div className="invalid-feedback">{errors.longitude}</div>}
  </div>
</div>

{/* Password Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Mot de passe
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="password"
      placeholder=""
      required
      name="password"
      value={formPassword}
      onChange={(e) => setFormPassword(e.target.value)}
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
    />
    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
  </div>
</div>

{/* Password Confirmation Input Group */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
      className="required fw-semibold text-right"
    >
      Confirmer le mot de passe
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    <input
      type="password"
      placeholder=""
      required
      name="password_confirmation"
      value={formPasswordConfirmation}
      onChange={(e) => setFormPasswordConfirmation(e.target.value)}
      style={{
        width: "100%",
        maxWidth: "160px",
        height: "27px",
        marginLeft: "5px",
      }}
      className={`form-control ${errors.password_confirmation ? 'is-invalid' : ''}`}
    />
    {errors.password_confirmation && <div className="invalid-feedback">{errors.password_confirmation}</div>}
  </div>
</div>


                              <button
                                onClick={handleFormSubmit}
                                type="button"
                                className="btn btn-primary mx-2"
                                // disabled={isLoading}
                              >
                                {loading ? "Chargement..." : "Enregistrer"}
                              </button>
                              <Link
                                // type="button"
                                className="btn btn-secondary mx-2"
                                to={"/techniciens"}
                              >
                                Annuler
                              </Link>
                            </form>
                            {/*end::Form */}
                            {/*begin::Job */}
                            <div className="mb-10 mb-lg-0"></div>
                            {/*end::Job */}
                          </div>
                          {/*end::Content */}
                        </div>
                        {/*end::Layout */}
                      </div>
                    </div>
                    {/*end::Careers - Apply */}
                  </div>
                  {/*end::Post */}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default CreateTechnicien;
