import React, { useState ,useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import axios from "axios";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Recherche from "./components/Recherche";
import InterventionsTab from "./components/InterventionsTab";
import TechniciensTab from "./components/TechniciensTab";
function DashbordPage() {
  const [widthLeft, setWidthLeft] = useState(600);
  const [widthRight, setWidthRight] = useState(200);
  const apiHost = process.env.REACT_APP_API_HOST;

  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true); // State to manage loading status

  const handleResize = (event, { size }) => {
    setWidthLeft(size.width);
    setWidthRight(window.innerWidth - size.width);
  };
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 31.792306,
    lng: -7.080168,
  };

  useEffect(() => {
  
	const fetchUsers = async () => {
		try {
		  const response = await axios.get(`${apiHost}/users`);
		  setUsers(response.data); // Assuming the API returns an array of users
		  setIsLoadingUsers(false); // Set isLoading to false after data is fetched
		} catch (error) {
		  console.error('Error fetching users:', error);
		  setIsLoadingUsers(false); // Set isLoading to false on error as well
		}
	  };

	fetchUsers();
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      {/* {/*begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {/*begin::Header */}
          <Header />
          {/*end::Header */}
          {/*begin::Toolbar */}

          {/*end::Toolbar */}
          {/*begin::Container */}
          <div style={{ display: "flex", height: "89vh" }}>
            <ResizableBox
              width={widthLeft}
              height={Infinity}
              axis="x"
              minConstraints={[100, Infinity]}
              maxConstraints={[window.innerWidth - 200, Infinity]} // Adjust max width to fit your needs
              resizeHandles={["e"]}
              onResizeStop={handleResize}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#f0f0f0",
                }}
              >
				<Recherche></Recherche>

                <div className="card card-xl-stretch " >
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Monitoring
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Verifier vos Interventions & Techniciens
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <ul className="nav">
                        <li className="nav-item">
                          <a
                            className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 active"
                            data-bs-toggle="tab"
                            href="#kt_table_widget_5_tab_1"
                          >
                            Interventions
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1"
                            data-bs-toggle="tab"
                            href="#kt_table_widget_5_tab_2"
                          >
                            Techniciens
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body p-0" style={{maxHeight:"65vh" ,overflow:"scroll"}}>
                    <div className="tab-content">
                      <InterventionsTab></InterventionsTab>
                      <TechniciensTab />
                     
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
              </div>
            </ResizableBox>
            <div
              style={{
                flexGrow: 1,
                width: widthRight,
                backgroundColor: "#e0e0e0",
              }}
            >
              
						<LoadScript googleMapsApiKey="AIzaSyDLsf_Bg2fD6QlB2DpIFWgsOJ4JX8CWXO8" region='MA'>
						<GoogleMap
							mapContainerStyle={containerStyle}
							center={center}
							zoom={6}
							options={{minZoom:"3"}}
						> 
							{/* Add any markers or additional components here */}
              </GoogleMap>
              </LoadScript>  
            </div>
          </div>

          {/*end::Container */}
          {/*begin::Footer */}
          {/* <Footer></Footer> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default DashbordPage;
