import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashbordPage from "./Pages/Dashboard/DashbordPage";
import Formulaires from "./Pages/Formulaires/Formulaires";
import Interventions from "./Pages/Interventions/Interventions";
import Activities from "./Pages/Activities/Activities";
import CreateActivity from "./Pages/Activities/CreateActivity";
import CreateIntervention from "./Pages/Interventions/CreateIntervention";
import ListTechnicien from "./Pages/Techniciens/ListTechnicien";
import FormBuilderPage from "./Pages/Formulaires/FormbuilderPage";
import Flags from "./Pages/Flags/Flags";
import Referentiel from "./Pages/Referentiel/Referentiel";
import ReferencielList from "./Pages/Referentiel/RefencielList";
import ValeurReferentielList from "./Pages/Referentiel/ValeurReferentielList";
import InterventionDetail from "./Pages/Interventions/InterventionDetail";
import EditForm from "./Pages/Formulaires/EditForm";
import SearchIntervention from "./Pages/SearchIntervention/SearchIntervention";
import ResultIntervention from "./Pages/SearchIntervention/ResultIntervention";
import UserActivitiesDetail from "./Pages/Planing/UserActivitiesDetail";
import EditActivity from "./Pages/Activities/EditActivity";
import CategoryReferentiel from "./Pages/Referentiel/CategoryReferentiel";
import CategoryReferentielValReferentiel from "./Pages/Referentiel/CategoryReferentielValReferentiel";
import InterventionType from "./Pages/Interventions/Type/InterventionType";
import CreateTechnicien from "./Pages/Techniciens/CreateTechnicien";
import CreateInterventionType from "./Pages/Interventions/Type/CreateInterventionType";
import EditTechnicien from "./Pages/Techniciens/EditTechnicien";
import Cartographie from "./Pages/Catrographie/Cartographie";
import UserActivites from "./Pages/Planing/UserActivites";
import EditInterventionType from "./Pages/Interventions/Type/EditInterventionType";
import DernieresPositions from "./Pages/Geolocalisation/dernierespositions";
import Historique from "./Pages/Geolocalisation/Historique";
import GoogleMapsLoader from "./components/GoogleMapsLoader";
// import CreateValRef from "./Pages/Referentiel/ValeurRef/CreateValRef";
// import AddReferentialValuePage from "./Pages/Referentiel/CreateValeurReferentiel";
// import Cartographie from "./Pages/Cartographie/Cartographie";

function App() {
  return (
    <Router>

      <div className="App">
      <GoogleMapsLoader googleMapsApiKey="AIzaSyDLsf_Bg2fD6QlB2DpIFWgsOJ4JX8CWXO8">
        <Routes>
          <Route path="/" element={<DashbordPage />} />
          <Route path="/formulaires" element={<Formulaires />} />
          <Route path="/formbuilder/:id" element={<FormBuilderPage />} />

          <Route path="/interventions" element={<Interventions />} />
          <Route
            path="/interventiondetail/:id"
            element={<InterventionDetail />}
          />

          <Route path="/techniciens" element={<ListTechnicien />} />
          <Route path="/techniciens/create" element={<CreateTechnicien />} />
          <Route path="/techniciens/edit/:id" element={<EditTechnicien />} /> 

          <Route path="/activities" element={<Activities />} />
          <Route path="/creatactivity" element={<CreateActivity />} />
          <Route path="/create-intervention" element={<CreateIntervention />} />

          <Route path="/flags" element={<Flags />} />

          <Route path="/referentiel" element={<Referentiel />} />
          <Route
            path="/referentiellist/:idCategory"
            element={<ReferencielList />}
          />
          <Route
            path="/categories-referentiel"
            element={<CategoryReferentiel />}
          />
          
          <Route
            path="/valeurreferentiel/:id"
            element={<ValeurReferentielList />}
          />

          <Route path="/editform/:id" element={<EditForm />} />
          <Route path="/search-intervention" element={<SearchIntervention />} />
          <Route
            path="/search-intervention/result"
            element={<ResultIntervention />}
          />
          <Route path="/edit-activitie/:id" element={<EditActivity />} />

          <Route path="/planing/:id" element={<UserActivitiesDetail />} />

          <Route path="/type-intervention" element={<InterventionType />} />
          <Route path="/type-intervention/create" element={<CreateInterventionType />} />
          <Route path="/type-intervention/edit/:id" element={<EditInterventionType />} />


          <Route path="/cartographie" element={<Cartographie />} />
          <Route path="/useractivites" element={<UserActivites />} />
          <Route path="/dernierespositions" element={<DernieresPositions />} />
          <Route path="/historique/:id" element={<Historique />} />

        </Routes>
      </GoogleMapsLoader>
      </div>
    </Router>
  );
}

export default App;
