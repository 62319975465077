import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../../Layout/menu/Footer";
import Header from "../../Layout/menu/Header";
import DataTable from "react-data-table-component";
function Activities() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // const [Searchstring, setSearchstring] = useState("");
  const [filteredActivities, setFilteredActivities] = useState(activities);
  const [Searchstring, setSearchstring] = useState("");

  useEffect(() => {
    setFilteredActivities(
      activities.filter((activitie) =>
        activitie.Nom.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, activities]);

  

  const navigate = useNavigate();

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    // Implement your deletion logic here
    setShowModal(false); // Close modal after deletion
  };
  const columns = [
    {
      name: "Nom",
      selector: (row) => row.Nom,
      sortable: true,
    },

    {
      name: "Code",
      selector: (row) => "",
      sortable: true,
    },
    {
      name: "Durée",
      selector: (row) => row.Durée_Unité,
      sortable: true,
    },
    {
      name: "Temps de travail",
      selector: (row) => row.Durée_Unité,
      sortable: true,
    },
    {
      name: "Couleur",
      selector: (row) => (
        <div
          style={{
            width: "12px",
            height: "14px",
            borderRadius:"2px",
            backgroundColor: row.Couleur,
          }}
        ></div>
      ),
    },
  ];
  const [filteredForms, setFilteredForms] = useState(activities);
  useEffect(() => {
    setFilteredForms(
      activities.filter((flag) =>
        flag.Nom.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, activities]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: '26px', // override the row height
        
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontWeight:"700",
        backgroundColor:"#fafafa "   
            
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        
      },
    },
  };
  useEffect(() => {
    fetch(`${apiHost}/activities`)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        setActivities(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      });
  }, [apiHost]);
  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div
              className="Customcontainer bg-white" 
            >
              <div className="row bg-white" style={{ flex: 1,minHeight:"91.1vh" }}>
                <div className="col-2 p-5 " style={{borderRight:"1px solid rgba(58,56,56,.1)"}}>
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Rechercher un type d'activité
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          data-kt-subscription-table-filter="search"
                          className="form-control form-control-solid  ps-12 mb-2 border"
                          placeholder="Recherche ..."
                          onChange={handleInputChange}
                        />
                      </div>
                    <div class="d-grid gap-2 col-6 mx-auto">
                    <Link to="/creatactivity" className="btn btn-primary">
                   Nouveau
                        </Link></div>                    </div>
                <div className="col-10" >
                  {/* Contenu de la colonne de droite */}
                  <h1 className="d-flex  fw-bold my-1 fs-3 p-4" style={{fontSize:"1.5em", fontWeight:"400"}}>
                    Liste des types d'activités

                  </h1>
                  <div className="card-body pt-0">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src="/assets/loading.svg" alt="Loading" />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <DataTable
                            columns={columns}
                            data={filteredForms}
                            pagination
                            highlightOnHover={true}
                            paginationPerPage={10} // Set the number of rows per page
                            onRowClicked={(row) => {
                              navigate(`/edit-activitie/${row.id}`);
                            }}
                            customStyles={customStyles}

                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
    </div>
  );
}

export default Activities;
