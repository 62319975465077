import React, { useEffect, useState } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";

const Cartographie = ({ zoomLevel = 6 }) => {
  const { state } = useLocation();
  const [points, setPoints] = useState([]); // Manage points state
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (state?.points) {
      setPoints(state.points);
    }
  }, [state]); // Update points on location change

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  // Log the received points to debug
  console.log("Points Received =>", points);

  // Determine the center of the map
  const defaultCenter = points.length > 0
    ? {
        lat: parseFloat(points[0].lat),
        lng: parseFloat(points[0].lng),
      }
    : {
        lat: 31.792306, // Default center
        lng: -7.080168,
      };

  // Ensure points are in the correct format
  const formattedPoints = points.map(point => ({
    lat: parseFloat(point.lat),
    lng: parseFloat(point.lng),
  }));

  // Log the formatted points to debug
  console.log("Formatted Points =>", formattedPoints);

  // Create a unique key for the GoogleMap component
  const mapKey = JSON.stringify(formattedPoints);

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="page d-flex flex-row flex-column-fluid" style={{ flex: 1 }}>
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
          style={{ flex: 1 }}
        >
          <Header />
          <div className="Customcontainer bg-white" style={{ flex: 1 }}>
            <div style={{ height: "100%" }}>
              {formattedPoints.length > 0 ? (
                <GoogleMap
                  key={mapKey}
                  mapContainerStyle={containerStyle}
                  center={defaultCenter}
                  zoom={zoomLevel}
                  options={{ minZoom: 3 }}
                  onLoad={() => setMapLoaded(true)}
                >
                  <Polyline
                    path={formattedPoints}
                    options={{ strokeColor: '#0000FF', strokeOpacity: 1.0, strokeWeight: 2 }}
                  />
                </GoogleMap>
              ) : (
                <GoogleMap
                key={mapKey}
                mapContainerStyle={containerStyle}
                center={defaultCenter}
                zoom={zoomLevel}
                options={{ minZoom: 3 }}
                onLoad={() => setMapLoaded(true)}
              >
                
              </GoogleMap>
              )}
            </div>
          </div>
          {/* Uncomment the Footer if needed */}
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default Cartographie;
