import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ColumnSelectionModal from "../../components/ColumnSelectionModal";

function Interventions() {
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(true);
  const [interventions, setInterventions] = useState([]);
  const [Searchstring, setSearchstring] = useState("");
  const [filteredIntervention, setFilteredIntervention] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([
    "Description",
    "Status",
    "Date Creation",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    setFilteredIntervention(
      interventions.filter((intervention) =>
        intervention.status.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, interventions]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  const getBadgeClass = (row) => {
    if (row.sold_at) {
      return 'badge-light-danger';
    } else if (row.started_at) {
      return 'badge-light-warning';
    } else if (row.downloaded_at) {
      return 'badge-light-info';
    } else {
      return 'badge-light-success';
    }
  };
  const getStatusLabel = (row) => {
    if (row.sold_at) {
      return 'Réalisée';
    } else if (row.started_at) {
      return 'Démarrée';
    } else if (row.downloaded_at) {
      return 'Synchronisée';
    } else {
      return 'Planifié';
    }
  };

  const columns = [
    {
      name: "Description",
      selector: (row) => row.description,
      omit: !selectedColumns.includes("Description"),
    },
    {
      name: "Status",
      selector: (row) => (
        <span className={`badge ${getBadgeClass(row)}`}>
          {getStatusLabel(row)}
        </span>
      ),
      omit: !selectedColumns.includes("Status"),
    },
    {
      name: "Date Creation",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      omit: !selectedColumns.includes("Date Creation"),
    },
    {
      name: "User Name",
      selector: (row) => `${row.user.prenom} ${row.user.nom}`,
      omit: !selectedColumns.includes("User Name"),
    },
    {
      name: "Intervention Type",
      selector: (row) => row.intervention_type?.name,
      omit: !selectedColumns.includes("Intervention Type"),
    },
    {
      name: "Form Name",
      selector: (row) => row.form.name,
      omit: !selectedColumns.includes("Form Name"),
    },
    {
      name: "Before Date",
      selector: (row) => new Date(row.beforeDate).toLocaleDateString(),
      omit: !selectedColumns.includes("Before Date"),
    },
    {
      name: "After Date",
      selector: (row) => new Date(row.afterDate).toLocaleDateString(),
      omit: !selectedColumns.includes("After Date"),
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/interventiondetail/${row.id}`);
    console.log("row===>", row);
  };

  useEffect(() => {
    fetch(`${apiHost}/interventionsFront`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Interventions API Response:", data);
        setInterventions(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching interventions:", error);
        setLoading(false);
      });
  }, [apiHost]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const handleColumnChange = (selectedOptions) => {
    // Extract values from the selected options
    const selectedValues = selectedOptions.map(option => option);
    setSelectedColumns(selectedValues);
  };
  

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Liste des interventions 
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid  ps-12 mb-2 border"
                      placeholder="Recherche ..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <Link to="/create-intervention" className="btn btn-primary">
                      Crée
                    </Link>
                  </div>
                  <ColumnSelectionModal
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnChange={handleColumnChange}
      />
                </div>
                <div className="col-10">
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Liste des interventions
                  </h1>
                  <div className="card-body pt-0">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src="/assets/loading.svg" alt="Loading" />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <DataTable
                            columns={columns.filter((col) => !col.omit)}
                            data={filteredIntervention}
                            pagination
                            highlightOnHover={true}
                            onRowClicked={handleRowClick}
                            paginationPerPage={10}
                            customStyles={customStyles}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interventions;
