import React from "react";
import { CiSearch, CiSettings } from "react-icons/ci";

function Recherche() {
 
  return (
    <>
    {/*begin::Form*/}
    <form action="#">
      {/*begin::Card*/}
      <div className="card ">
        {/*begin::Card body*/}
        <div className="card-body">
          {/*begin::Compact form*/}
          <div className="d-flex align-items-center">
            {/*begin::Input group*/}
            <div className="position-relative w-md-400px me-md-2">
              <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-6">
              <CiSearch  />
             
              </i>
              <input
                type="text"
                className="form-control form-control-solid ps-10"
                name="Recherche"
                defaultValue=""
                placeholder="Recherche"
              />
            </div>
            {/*end::Input group*/}
            {/*begin:Action*/}
            <div className="d-flex align-items-center">
              <button type="submit" className="btn btn-primary me-5">
                Recherche
              </button>
            
            </div>
            {/*end:Action*/}
          </div>
          {/*end::Compact form*/}
          {/*begin::Advance form*/}
          <div className="collapse" id="kt_advanced_Recherche_form">
            {/*begin::Separator*/}
            <div className="separator separator-dashed mt-9 mb-6" />
            {/*end::Separator*/}
            {/*begin::Row*/}
            <div className="row g-8 mb-8">
              {/*begin::Col*/}
              <div className="col-xxl-7">
                <label className="fs-6 form-label fw-bold text-gray-900">
                  Tags
                </label>
                <input
                  type="text"
                  className="form-control form-control form-control-solid"
                  name="tags"
                  defaultValue="products, users, events"
                />
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xxl-5">
                {/*begin::Row*/}
                <div className="row g-8">
                  {/*begin::Col*/}
                  <div className="col-lg-6">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Team Type
                    </label>
                    {/*begin::Select*/}
                    <select
                      className="form-select form-select-solid"
                      data-control="select2"
                      data-placeholder="In Progress"
                      data-hide-Recherche="true"
                    >
                      <option value="" />
                      <option value={1}>Not started</option>
                      <option value={2} selected="selected">
                        In Progress
                      </option>
                      <option value={3}>Done</option>
                    </select>
                    {/*end::Select*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-lg-6">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Select Group
                    </label>
                    {/*begin::Radio group*/}
                    <div className="nav-group nav-group-fluid">
                      {/*begin::Option*/}
                      <label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          defaultValue="has"
                          defaultChecked="checked"
                        />
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">
                          All
                        </span>
                      </label>
                      {/*end::Option*/}
                      {/*begin::Option*/}
                      <label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          defaultValue="users"
                        />
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">
                          Users
                        </span>
                      </label>
                      {/*end::Option*/}
                      {/*begin::Option*/}
                      <label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          defaultValue="orders"
                        />
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4">
                          Orders
                        </span>
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Radio group*/}
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row g-8">
              {/*begin::Col*/}
              <div className="col-xxl-7">
                {/*begin::Row*/}
                <div className="row g-8">
                  {/*begin::Col*/}
                  <div className="col-lg-4">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Min. Amount
                    </label>
                    {/*begin::Dialer*/}
                    <div
                      className="position-relative"
                      data-kt-dialer="true"
                      data-kt-dialer-min={1000}
                      data-kt-dialer-max={50000}
                      data-kt-dialer-step={1000}
                      data-kt-dialer-prefix="$"
                      data-kt-dialer-decimals={2}
                    >
                      {/*begin::Decrease control*/}
                      <button
                        type="button"
                        className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                        data-kt-dialer-control="decrease"
                      >
                        <i className="ki-duotone ki-minus-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </button>
                      {/*end::Decrease control*/}
                      {/*begin::Input control*/}
                      <input
                        type="text"
                        className="form-control form-control-solid border-0 ps-12"
                        data-kt-dialer-control="input"
                        placeholder="Amount"
                        name="manageBudget"
                        readOnly="readonly"
                        defaultValue="$50"
                      />
                      {/*end::Input control*/}
                      {/*begin::Increase control*/}
                      <button
                        type="button"
                        className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                        data-kt-dialer-control="increase"
                      >
                        <i className="ki-duotone ki-plus-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </button>
                      {/*end::Increase control*/}
                    </div>
                    {/*end::Dialer*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-lg-4">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Max. Amount
                    </label>
                    {/*begin::Dialer*/}
                    <div
                      className="position-relative"
                      data-kt-dialer="true"
                      data-kt-dialer-min={1000}
                      data-kt-dialer-max={50000}
                      data-kt-dialer-step={1000}
                      data-kt-dialer-prefix="$"
                      data-kt-dialer-decimals={2}
                    >
                      {/*begin::Decrease control*/}
                      <button
                        type="button"
                        className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                        data-kt-dialer-control="decrease"
                      >
                        <i className="ki-duotone ki-minus-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </button>
                      {/*end::Decrease control*/}
                      {/*begin::Input control*/}
                      <input
                        type="text"
                        className="form-control form-control-solid border-0 ps-12"
                        data-kt-dialer-control="input"
                        placeholder="Amount"
                        name="manageBudget"
                        readOnly="readonly"
                        defaultValue="$100"
                      />
                      {/*end::Input control*/}
                      {/*begin::Increase control*/}
                      <button
                        type="button"
                        className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                        data-kt-dialer-control="increase"
                      >
                        <i className="ki-duotone ki-plus-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </button>
                      {/*end::Increase control*/}
                    </div>
                    {/*end::Dialer*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-lg-4">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Team Size
                    </label>
                    <input
                      type="text"
                      className="form-control form-control form-control-solid"
                      name="city"
                    />
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xxl-5">
                {/*begin::Row*/}
                <div className="row g-8">
                  {/*begin::Col*/}
                  <div className="col-lg-6">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Category
                    </label>
                    {/*begin::Select*/}
                    <select
                      className="form-select form-select-solid"
                      data-control="select2"
                      data-placeholder="In Progress"
                      data-hide-Recherche="true"
                    >
                      <option value="" />
                      <option value={1}>Not started</option>
                      <option value={2} selected="selected">
                        Select
                      </option>
                      <option value={3}>Done</option>
                    </select>
                    {/*end::Select*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className="col-lg-6">
                    <label className="fs-6 form-label fw-bold text-gray-900">
                      Status
                    </label>
                    <div className="form-check form-switch form-check-custom form-check-solid mt-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="flexSwitchChecked"
                        defaultChecked="checked"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchChecked"
                      >
                        Active
                      </label>
                    </div>
                  </div>
                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Advance form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}
    </form>
    {/*end::Form*/}
  </>
  );
}

export default Recherche;
