import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import ColumnSelectionModal from "../../components/ColumnSelectionModal";
function DernieresPositions() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const [Searchstring, setSearchstring] = useState("");
  const [filteredForms, setFilteredForms] = useState(forms);
  useEffect(() => {
    setFilteredForms(
      forms.filter((technicien) =>
        technicien.nom.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, forms]);
  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };
  const [selectedColumns, setSelectedColumns] = useState([
    "Nom", "Prénom", "Email" ,"Actions"
  ]);
  const handleClicked = (id) => {
    navigate(`/historique/${id}`);
  };
  useEffect(() => {
    fetch(`${apiHost}/users`)
      .then((response) => response.json())
      .then((data) => {
        setForms(data);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [loading]);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      omit: !selectedColumns.includes("ID"),
    },
    {
      name: "Prénom",
      selector: (row) => row.prenom,
      omit: !selectedColumns.includes("Prénom"),
    },
    {
      name: "Nom",
      selector: (row) => row.nom,
      omit: !selectedColumns.includes("Nom"),
    },
    {
      name: "Code",
      selector: (row) => row.code,
      omit: !selectedColumns.includes("Code"),
    },
    {
      name: "Téléphone",
      selector: (row) => row.telephone,
      omit: !selectedColumns.includes("Téléphone"),
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      omit: !selectedColumns.includes("Adresse"),
    },
    {
      name: "Code Postal",
      selector: (row) => row.code_postal,
      omit: !selectedColumns.includes("Code Postal"),
    },
    {
      name: "Ville",
      selector: (row) => row.ville,
      omit: !selectedColumns.includes("Ville"),
    },
    {
      name: "Latitude",
      selector: (row) => row.latitude,
      omit: !selectedColumns.includes("Latitude"),
    },
    {
      name: "Longitude",
      selector: (row) => row.longitude,
      omit: !selectedColumns.includes("Longitude"),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      omit: !selectedColumns.includes("Email"),
    },
    {
      name: "Date de Création",
      selector: (row) => {
        const createdAtDate = new Date(row.created_at);
        return createdAtDate.toLocaleDateString();
      },
      sortable: true,
      omit: !selectedColumns.includes("Date de Création"),
    },
    {
      name: "Date de Mise à Jour",
      selector: (row) => {
        const updatedAtDate = new Date(row.updated_at);
        return updatedAtDate.toLocaleDateString();
      },
      sortable: true,
      omit: !selectedColumns.includes("Date de Mise à Jour"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          variant="link"
          style={{ color: "#007bff" }}
          onClick={() => navigate(`/techniciens/edit/${row.id}`)}
        >
          <FaEdit style={{ fontSize: "24px" }} />
        </Button>
      ),
      omit: !selectedColumns.includes("Actions"),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const handleColumnChange = (selectedOptions) => {
    // Extract values from the selected options
    const selectedValues = selectedOptions.map(option => option);
    setSelectedColumns(selectedValues);
  };
  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  ></p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche ..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button
                      onClick={() =>navigate(`/techniciens/create`)}
                      className="btn btn-primary"
                    >
                      Nouveau
                    </button>
                  </div>{" "}
                  <div className="column-selection mt-4">
                    <ColumnSelectionModal
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnChange={handleColumnChange}
      />
                  </div>
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Dernières positions

                  </h1>
                  {/*begin::Card body */}
                  <div className="card-body pt-0">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src="/assets/loading.svg" alt="Loading" />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <DataTable
                            columns={columns}
                            data={filteredForms}
                            pagination
                            highlightOnHover={true}
                            paginationPerPage={10} // Set the number of rows per page
                            onRowClicked={(row) => {
                              handleClicked(row.id);
                            }}
                            customStyles={customStyles}
                          />
                        )}
                      </>
                    )}
                  </div>

                  {/*end::Card body */}
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
     
    </div>
  );
}

export default DernieresPositions;
