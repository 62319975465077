import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { CirclePicker, CompactPicker } from "react-color";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa";

function CreateActivity() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({}); // State to store validation errors
  const [selected, setSelected] = useState([]);
  const [optionsactivites, setOptionsActivities] = useState([]);

  const [formData, setFormData] = useState({
    Nom: "",
    Couleur: "#FF5252",
    Durée_Unité: "Heures, minutes",
    debut: "Date et heure système",
    Fin: "Activité suivante",
    Temps_de_travail: "Non",
    Imputable_à_une_intervention: false,
    Début_d_intervention: false,
    Facturable: false,
    Temps_réel: false,
    Activité_initiale: false,
    Accès_compte_rendu_sur_le_mobile: "Non",
    Accès_solde: false,
    Fin_avec_solde: false,
    Utilisable: "Web et Mobile",
    Modifiable: "Non",
    Relevé_GPS: "Jamais",
    // Ajouter_une_activité_suivante: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(true);

  const onChange = (s) => {
    setSelected(s);
  };

  useEffect(() => {
    let ajouterActiviteSuivante = false;
    const selectedOptions = optionsactivites.filter((option) =>
      selected.includes(option.value)
    );
    let selectedOptionsString = null;

    if (selectedOptions.length > 0) {
      ajouterActiviteSuivante = true;
      selectedOptionsString = JSON.stringify(selectedOptions);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      // Ajouter_une_activité_suivante: ajouterActiviteSuivante,
      Activitessuivante: selectedOptionsString,
    }));
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiHost}/activities/`);
        const activities = response.data.map((activity) => ({
          value: activity.id,
          label: activity.Nom,
        }));
        setOptionsActivities(activities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeBoolean = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value === "true", // Convert string to boolean
    }));
  };

  const handleColorChange = (color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Couleur: color.hex,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${apiHost}/activities`, formData);

      // Redirect to '/activities' aaffter success
      navigate("/activities");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        // Backend validation errors structure assumed to be err.response.data.errors
        setErrors(err.response.data.errors);
      } else {
        setError(err.message || "An error occurred. Please try again.");
      }
      console.error("Error submitting form:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const colors = [
    "#FF5252", // Red
    "#E040FB", // Purple
    "#448AFF", // Blue
    "#FFFF00", // Yellow
    "#00FF00", // Green
    "#000000", // Black
  ];
  // State to manage collapse
  const [isOpen, setIsOpen] = useState(false);

  // Toggle collapse state
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {/*begin::Header */}
          <Header />
          {/*end::Header */}
          <div className="Customcontainer bg-white">
            <div
              className="row bg-white"
              style={{ flex: 1, minHeight: "91.1vh" }}
            >
              <div
                className="col-2 p-5 "
                style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
              >
                {/* Contenu de la colonne de gauche */}
                <p
                  className="etech-sidebar-section"
                  style={{
                    color: "#3a3838",
                    fontWeight: "700",
                    padding: ".25em 0",
                  }}
                >
                  Rechercher un type d'activité
                </p>
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-subscription-table-filter="search"
                    className="form-control form-control-solid  ps-12 mb-2 border"
                    placeholder="Recherche ..."
                    onChange={"handleInputChange"}
                    disabled
                  />
                </div>
                <div class="d-grid gap-2 col-6 mx-auto">
                  <Link to="/creatactivity" className="btn btn-primary">
                    Rechercher
                  </Link>
                </div>{" "}
              </div>
              <div className="col-10">
                {/* Contenu de la colonne de droite */}
                <h1
                  className="d-flex  fw-bold my-1 fs-3 p-4"
                  style={{ fontSize: "1.5em", fontWeight: "400" }}
                >
                  Nouveau type d'activité
                </h1>
                <div className="card-body pt-0">
                  {/*begin::Post */}
                  <div className="content flex-row-fluid" id="kt_content">
                    {/*begin::Careers - Apply */}
                    <div className="card">
                      {/*begin::Body */}
                      <div className="card-body p-3">
                        {/*begin::Hero */}

                        <div className="d-flex flex-column flex-lg-row mb-17">
                          {/*begin::Content */}
                          <div className="flex-lg-row-fluid me-0 ">
                            {/*begin::Form */}

                            <form
                              action="m-0"
                              className="form mb-15"
                              method="post"
                              id="kt_careers_form"
                              onSubmit={handleSubmit}
                            >
                              {/*begin::Input group */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px", // Adjust the padding as needed
                                      paddingTop: "5px",
                                      fontSize: "14px", // Adjust padding to slightly move label down
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Nom
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Input Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <input
                                    type="text"
                                    value={formData.Nom}
                                    onChange={handleChange}
                                    placeholder=""
                                    required
                                    name="Nom"
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px", // Adjust the margin as needed
                                    }}
                                    className={`form-control ${
                                      errors.Nom ? "is-invalid" : ""
                                    }`}
                                  />
                                  {errors.Nom && (
                                    <div className="invalid-feedback">
                                      {errors.Nom[0]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/*end::Input group */}

                              {/*begin::Input group */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="fw-semibold  text-right"
                                  >
                                    Code
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Input Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <input
                                    type="text"
                                    className={`form-control mb-2 ${
                                      errors.code ? "is-invalid" : ""
                                    }`}
                                    defaultValue={formData.Code}
                                    onChange={handleChange}
                                    placeholder=""
                                    name="code"
                                    disabled
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                  />
                                  {errors.code && (
                                    <div className="invalid-feedback">
                                      {errors.code[0]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/*end::Input group */}
                              {/*begin::Input group */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Couleur
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Input Column */}
                                <div className="col-md-9 position-relative">
                                  {showColorPicker && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        zIndex: "2",
                                        marginLeft: "5px",
                                        marginBottom: "0", // Adjust the margin to align the color picker
                                      }}
                                    >
                                      <CirclePicker
                                        color={formData.Couleur}
                                        colors={colors}
                                        onChange={handleColorChange}
                                        className={`mb-2 ${
                                          errors.Couleur ? "is-invalid" : ""
                                        }`}
                                        circleSize={20}
                                      />
                                      {errors.Couleur && (
                                        <div className="invalid-feedback mb-2">
                                          {errors.Couleur[0]}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/*end::Input group */}

                              {/*begin::Input group */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="fw-semibold  text-right"
                                  >
                                    Durée Unité
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Durée_Unité"
                                          value="Jours"
                                          checked={
                                            formData.Durée_Unité === "Jours"
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Jours
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Durée_Unité"
                                          value="Heures, minutes"
                                          checked={
                                            formData.Durée_Unité ===
                                              "Heures, minutes" ||
                                            !formData.Durée_Unité
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Heures, minutes
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end::Input group */}

                              {/*begin::Input group */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Début
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="debut"
                                          value="Saisie manuelle"
                                          checked={
                                            formData.debut === "Saisie manuelle"
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Saisie manuelle
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="debut"
                                          value="Date et heure système"
                                          defaultChecked
                                          checked={
                                            formData.debut ===
                                              "Date et heure système" ||
                                            !formData.debut
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Date et heure système
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end::Input group */}

                              {/*begin::Input group */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Fin
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Fin"
                                          value="Saisie manuelle"
                                          checked={
                                            formData.Fin === "Saisie manuelle"
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Saisie manuelle
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Fin"
                                          value="Activité suivante"
                                          checked={
                                            formData.Fin ===
                                              "Activité suivante" ||
                                            !formData.Fin
                                          }
                                          defaultChecked
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Activité suivante
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end::Input group */}

                              {/* Temps de travail */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  {/*begin::Label */}
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Temps de travail
                                  </label>
                                  {/*end::Label */}
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Temps_de_travail"
                                          value="Oui"
                                          checked={
                                            formData.Temps_de_travail ===
                                              "Oui" ||
                                            !formData.Temps_de_travail
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Oui
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Temps_de_travail"
                                          value="Oui, bloqué"
                                          checked={
                                            formData.Temps_de_travail ===
                                            "Oui, bloqué"
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Oui, bloqué
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Temps_de_travail"
                                          value="Non"
                                          checked={
                                            formData.Temps_de_travail === "Non"
                                          }
                                          onChange={handleChange}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Imputable à une intervention */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Imputable à une intervention
                                  </label>
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Imputable_à_une_intervention"
                                          value={true}
                                          checked={
                                            formData.Imputable_à_une_intervention
                                          }
                                          onChange={handleChangeBoolean}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Oui
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Imputable_à_une_intervention"
                                          value={false}
                                          checked={
                                            !formData.Imputable_à_une_intervention
                                          }
                                          onChange={handleChangeBoolean}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Début d'intervention */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Début d'intervention
                                  </label>
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Début_d_intervention"
                                          value={true}
                                          checked={
                                            formData.Début_d_intervention
                                          }
                                          onChange={handleChangeBoolean}
                                          className="m-3"
                                          required
                                        />
                                        <span></span>
                                        Oui
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Début_d_intervention"
                                          value={false}
                                          checked={
                                            !formData.Début_d_intervention
                                          }
                                          onChange={handleChangeBoolean}
                                          className="m-3"
                                          required
                                        />
                                        <span></span>
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Facturable */}
                              <div className="row mb-3">
                                {/* Label Column */}
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                    }}
                                    className="required fw-semibold  text-right"
                                  >
                                    Facturable
                                  </label>
                                </div>
                                {/* Radio Buttons Column */}
                                <div
                                  className="col-md-9"
                                  style={{ borderLeft: "1px solid #ccc" }}
                                >
                                  <div className="form-group row">
                                    <div className="radio-inline">
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Facturable"
                                          value={true}
                                          checked={formData.Facturable}
                                          onChange={handleChangeBoolean}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Oui
                                      </label>
                                      <label className="radio radio-success">
                                        <input
                                          type="radio"
                                          name="Facturable"
                                          value={false}
                                          checked={!formData.Facturable}
                                          onChange={handleChangeBoolean}
                                          className="m-3"
                                        />
                                        <span></span>
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*begin::Section */}
                              <div className="m-0">
                                {/*begin::Heading */}
                                <div
                                  className="d-flex align-items-center collapsible py-3 toggle mb-0"
                                  data-bs-toggle="collapse"
                                  aria-expanded={isOpen ? "true" : "false"}
                                  data-bs-target="#kt_job_3_1"
                                  onClick={toggleCollapse}
                                >
                                  {/*begin::Icon */}
                                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                    {isOpen ? (
                                      <FaChevronDown />
                                    ) : (
                                      <FaChevronRight />
                                    )}
                                  </div>
                                  {/*end::Icon */}
                                  {/*begin::Title */}
                                  <h4
                                    className="text-black fw-bold cursor-pointer mb-0"
                                    style={{
                                      fontWeight: "700",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Paramètres avancés
                                  </h4>
                                  {/*end::Title */}
                                </div>
                                {/*end::Heading */}
                                {/*begin::Body */}
                                <div
                                  id="kt_job_3_1"
                                  className="collapse  fs-6 ms-1"
                                >
                                  {/*begin::Item */}
                                  {/*begin::Input group */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          color: "#0099e5",
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px", // Adjust the padding as needed
                                          paddingTop: "5px",
                                          fontSize: "14px", // Adjust padding to slightly move label down
                                        }}
                                        className="required fw-semibold text-right"
                                      >
                                        Section lié
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <input
                                        type="text"
                                        value={formData.Section_liée}
                                        onChange={handleChange}
                                        placeholder=""
                                        name="Section_liée"
                                        style={{
                                          width: "100%",
                                          maxWidth: "160px",
                                          height: "27px",
                                          marginLeft: "5px", // Adjust the margin as needed
                                        }}
                                        className={`form-control ${
                                          errors.Section_liée
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        disabled
                                        readOnly
                                        required
                                      />
                                      {errors.Section_liée && (
                                        <div className="invalid-feedback">
                                          {errors.Section_liée[0]}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {/*end::Input group */}

                                  {/*begin::Item */}
                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          color: "#0099e5",
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="required fs-5 fw-semibold text-right"
                                      >
                                        Temps réel
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Temps_réel"
                                            className="m-2"
                                            value={true}
                                            checked={formData.Temps_réel}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Oui
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Temps_réel"
                                            className="m-2"
                                            value={false}
                                            checked={!formData.Temps_réel}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          color: "#0099e5",
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="required fw-semibold text-right"
                                      >
                                        Activité initiale
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Activité_initiale"
                                            className="m-3"
                                            value={true}
                                            checked={formData.Activité_initiale}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Oui
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Activité_initiale"
                                            className="m-3"
                                            value={false}
                                            checked={
                                              !formData.Activité_initiale
                                            }
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="fs-5 fw-semibold text-right"
                                      >
                                        Compte rendu sur le mobile
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Accès_compte_rendu_sur_le_mobile"
                                            className="m-2"
                                            value="Toutes interventions"
                                            checked={
                                              formData.Accès_compte_rendu_sur_le_mobile ===
                                              "Toutes interventions"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Toutes interventions
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Accès_compte_rendu_sur_le_mobile"
                                            className="m-2"
                                            value="Intervention liée"
                                            checked={
                                              formData.Accès_compte_rendu_sur_le_mobile ===
                                              "Intervention liée"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Intervention liée
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Accès_compte_rendu_sur_le_mobile"
                                            className="m-2"
                                            value="Non"
                                            checked={
                                              formData.Accès_compte_rendu_sur_le_mobile ===
                                              "Non"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="fs-5 fw-semibold text-right"
                                      >
                                        Accès solde
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Accès_solde"
                                            className="m-3"
                                            value={true}
                                            checked={formData.Accès_solde}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Oui
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Accès_solde"
                                            className="m-3"
                                            value={false}
                                            checked={!formData.Accès_solde}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="fs-5 fw-semibold text-right"
                                      >
                                        Fin Accès solde
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Fin_avec_solde"
                                            className="m-3"
                                            value={true}
                                            checked={formData.Fin_avec_solde}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Oui
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Fin_avec_solde"
                                            className="m-3"
                                            value={false}
                                            checked={!formData.Fin_avec_solde}
                                            onChange={handleChangeBoolean}
                                          />
                                          <span></span>
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          color: "#0099e5",
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="required fs-5 fw-semibold text-right"
                                      >
                                        Utilisable
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Utilisable"
                                            className="m-2"
                                            value="Web"
                                            checked={
                                              formData.Utilisable === "Web"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Web
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Utilisable"
                                            className="m-2"
                                            value="Mobile"
                                            checked={
                                              formData.Utilisable === "Mobile"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Mobile
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Utilisable"
                                            className="m-2"
                                            value="Web et Mobile"
                                            checked={
                                              formData.Utilisable ===
                                              "Web et Mobile"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Web & Mobile
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          color: "#0099e5",
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="required fs-5 fw-semibold text-right"
                                      >
                                        Modifiable
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Modifiable"
                                            className="m-2"
                                            value="Web"
                                            checked={
                                              formData.Modifiable === "Web"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Web
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Modifiable"
                                            className="m-2"
                                            value="Mobile"
                                            checked={
                                              formData.Modifiable === "Mobile"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Mobile
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Modifiable"
                                            className="m-2"
                                            value="Web et Mobile"
                                            checked={
                                              formData.Modifiable ===
                                              "Web et Mobile"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Web & Mobile
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Modifiable"
                                            className="m-2"
                                            value="Non"
                                            checked={
                                              formData.Modifiable === "Non"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="fs-5 fw-semibold text-right"
                                      >
                                        Relevé GPS
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-9"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="radio-inline">
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Relevé_GPS"
                                            className="m-2"
                                            value="Jamais"
                                            checked={
                                              formData.Relevé_GPS === "Jamais"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Jamais
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Relevé_GPS"
                                            className="m-2"
                                            value="À la création"
                                            checked={
                                              formData.Relevé_GPS ===
                                              "À la création"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>À la création
                                        </label>
                                        <label className="radio radio-success">
                                          <input
                                            type="radio"
                                            name="Relevé_GPS"
                                            className="m-2"
                                            value="Continu"
                                            checked={
                                              formData.Relevé_GPS === "Continu"
                                            }
                                            onChange={handleChange}
                                          />
                                          <span></span>
                                          Continu
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}

                                  {/*begin::Item */}
                                  <div className="row mb-3">
                                    {/* Label Column */}
                                    <div className="col-md-3 d-flex justify-content-end">
                                      {/*begin::Label */}
                                      <label
                                        style={{
                                          whiteSpace: "nowrap",
                                          paddingRight: "10px",
                                          paddingTop: "5px",
                                          fontSize: "14px",
                                        }}
                                        className="fs-5 fw-semibold text-right"
                                      >
                                        Ajouter une activité suivante
                                      </label>
                                      {/*end::Label */}
                                    </div>
                                    {/* Input Column */}
                                    <div
                                      className="col-md-4"
                                      style={{ borderLeft: "1px solid #ccc" }}
                                    >
                                      <div className="dual-listbox-wrapper">
                                        <DualListBox
                                          options={optionsactivites}
                                          onChange={onChange}
                                          selected={selected}
                                          preserveSelectOrder
                                          showOrderButtons
                                          canFilter
                                          style={{ widht: "40px" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/*end::Item */}
                                </div>
                                {/*end::Content */}
                                {/*begin::Separator */}
                              </div>
                              {/*end::Section */}

                              <button
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-primary mx-2"
                                disabled={isLoading}
                              >
                                {isLoading ? "Chargement..." : "Enregistrer"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary mx-2"
                              >
                                Annuler
                              </button>
                            </form>
                            {/*end::Form */}
                            {/*begin::Job */}
                            <div className="mb-10 mb-lg-0"></div>
                            {/*end::Job */}
                          </div>
                          {/*end::Content */}
                        </div>
                        {/*end::Layout */}
                      </div>
                    </div>
                    {/*end::Careers - Apply */}
                  </div>
                  {/*end::Post */}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default CreateActivity;
