import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import "./intervention.css";
import ImageGallery from 'react-image-gallery'; // Import ImageGallery component
import 'react-image-gallery/styles/css/image-gallery.css'; // Import ImageGallery styles
import Footer from '../../Layout/menu/Footer';
import Header from '../../Layout/menu/Header';
import { CiImageOn } from 'react-icons/ci';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

function InterventionDetail() {
    const { id } = useParams();
    const apiHost = process.env.REACT_APP_API_HOST;
    const [intervention, setIntervention] = useState([]);
    const [loading, setLoading] = useState(true);
    const ASSETSURL = process.env.REACT_APP_BACKEND_ASSETS;
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageGalleryImages, setImageGalleryImages] = useState([]);
    const [showGallery, setShowGallery] = useState(false);

    useEffect(() => {
        // console.log("interventionId",id);
        // Fetch the data for the selected intervention
        fetch(`${apiHost}/interventionsshowFront/${id}`)
            .then(response => response.json())
            .then(data => {
                console.log('Intervention Detail API Response:', data);
                setIntervention(data.data);
                setLoading(false);
                // Construct the images array
                const images = data.data.intervention_fields
                    .filter(field => field.field.field_type_id === 2 && field.value)
                    .map(field => ({
                        original: ASSETSURL + field.value,
                        thumbnail: ASSETSURL + field.value,
                        description: field.field.name // Assuming field.name is the title of the image
                    }));
                // Set images array
                console.log(images);
                setImageGalleryImages(images);

            })
            .catch(error => {
                console.error('Error fetching intervention detail:', error);
                setLoading(false);
            });
    },  [apiHost, id]);
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };
    const toggleGallery = () => {
        setShowGallery(!showGallery);
      };

    if (loading) {
        return <div>Loading...</div>;
    }

    const getParentHierarchy = (valeurReferentiel) => {
      const hierarchy = [];
      let current = valeurReferentiel;

      // Include the current valeur_referentiel itself
      hierarchy.push(current);

      while (current.parent) {
          current = current.parent;
          hierarchy.push(current);
      }

      return hierarchy.reverse(); // Reverse to display from root to leaf
  };

  const parentHierarchy = intervention.valeur_referentiel ? getParentHierarchy(intervention.valeur_referentiel) : [];


    return (<>
             <div>

<div className="d-flex flex-column flex-root">
  {/* {/*begin::Page */}
  <div className="page d-flex flex-row flex-column-fluid">
    {/*begin::Wrapper */}
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      {/*begin::Header */}

<Header/>

      {/*end::Header */}
      {/*begin::Toolbar */}
      <div className="toolbar py-5 pb-lg-15" id="kt_toolbar">
        {/*begin::Container */}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
          {/*begin::Page title */}
          <div className="page-title d-flex flex-column me-3">
            {/*begin::Title */}
            <h1 className="d-flex text-white fw-bold my-1 fs-3">Intervention Detail</h1>
            {/*end::Title */}
            {/*begin::Breadcrumb */}
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
              {/*begin::Item */}
              <li className="breadcrumb-item text-white opacity-75">
              <a href="/" className="text-white text-hover-primary">Home</a>
              </li>
              {/*end::Item */}
              {/*begin::Item */}
              <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
              </li>
              {/*end::Item */}
          
              {/*begin::Item */}
              <li className="breadcrumb-item text-white opacity-75">Interventions</li>
              {/*end::Item */}
              {/*begin::Item */}
              <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
              </li>
              {/*end::Item */}
              {/*begin::Item */}
              <li className="breadcrumb-item text-white opacity-75">Intervention detail</li>
              {/*end::Item */}
            </ul>
            {/*end::Breadcrumb */}
          </div>
          {/*end::Page title */}
          {/*begin::Actions */}
     
          {/*end::Actions */}
        </div>
        {/*end::Container */}
      </div>
      {/*end::Toolbar */}
      {/*begin::Container */}
      <div
  id="kt_content_container"
  className="d-flex flex-column-fluid align-items-start  container-xxl "
>
  {/*begin::Post*/}
  <div className="content flex-row-fluid" id="kt_content">
    {/*begin::Layout*/}
    <div className="d-flex flex-column flex-lg-row">
      {/*begin::Content*/}
      <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
        {/*begin::Card*/}
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
          {/*begin::Card header*/}
          <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
              <h2 className="fw-bold">Detail d'intervention</h2>
            </div>
            {/*begin::Card title*/}
            
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-3">
            {/*begin::Section*/}
            <div className="mb-10">
              {/*begin::Title*/}
              <h5 className="mb-4">Information generale:</h5>
              {/*end::Title*/}
              {/*begin::Details*/}
              <div className="d-flex flex-wrap py-5">
                {/*begin::Row*/}
                <div className="flex-equal me-5">
                  {/*begin::Details*/}
                  <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                    {/*begin::Row*/}
                    <tbody>
                      <tr>
                        <td className="text-gray-500 min-w-175px w-175px">
                          Agence:
                        </td>
                        <td className="text-gray-800 min-w-200px">
                          <a
                            href="/metronic8/demo2/pages/apps/customers/view.html"
                            className="text-gray-800 text-hover-primary"
                          >
                            Agence Principale
                          </a>
                        </td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Créée :</td>
                        <td className="text-gray-800">{intervention.created_at}</td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">A faire après	:</td>
                        <td className="text-gray-800">
                        {intervention.afterDate}
                        </td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">À faire avant	:</td>
                        <td className="text-gray-800">{intervention.beforeDate} </td>
                      </tr>
                      {/*end::Row*/}
                    </tbody>
                  </table>
                  {/*end::Details*/}
                </div>
                {/*end::Row*/}
                {/*begin::Row*/}
                <div className="flex-equal">
                  {/*begin::Details*/}
                  <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                    {/*begin::Row*/}
                    <tbody>
                      <tr>
                        <td className="text-gray-500 min-w-175px w-175px">
                          Drapeaux:
                        </td>
                        <td className="text-gray-800 min-w-200px">
                        {intervention.flag && (
                                                                                <span className="badge me-2 text-white" style={{ backgroundColor: intervention.flag.color }}>
                                                                                    {intervention.flag.name}
                                                                                </span>
                                                                            )}
                        </td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Description:</td>
                        <td className="text-gray-800">{intervention.description}
                        </td>
                      </tr>
                      {/*end::Row*/}
                      
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Type d'intervention	:</td>
                        <td className="text-gray-800">{intervention.intervention_type?.name}</td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Formulaire Lié	:</td>
                        <td className="text-gray-800">{intervention.form.name}</td>
                      </tr>
                      {/*end::Row*/}
                    </tbody>
                  </table>
                  {/*end::Details*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="mb-0">
              {/*begin::Title*/}
              <h5 className="mb-4">Technicien Participants:</h5>
              {/*end::Title*/}
              {/*begin::Product table*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr className="border-bottom border-gray-200 text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                      <th className="min-w-150px">Nom</th>
                      <th className="min-w-125px">Email</th>
                      
                    </tr>
                    {/*end::Table row*/}
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="fw-semibold text-gray-800">
                    <tr>
                      <td>
                        <label className="w-150px">{intervention.user.nom}</label>
                        
                      </td>
                      <td>
                        <span className="badge badge-light-danger">
                        {intervention.user.email}
                        </span>
                      </td>
                      
                    </tr>
                    
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Product table*/}
            </div>
            {/*end::Section*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Card*/}

        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
  {/*begin::Card header*/}
  <div className="card-header cursor-pointer">
    {/*begin::Card title*/}
    <div className="card-title m-0">
      <h3 className="fw-bold m-0">Formulaire ({intervention.form.name})</h3>
    </div>
    {/*end::Card title*/}
    {/*begin::Action*/}
        <div className='m-5 d-flex align-items-center'>
            
            <span className='text-gray-500 mr-2'>          {showGallery ? 'Hide Gallery' : 'Show Gallery'}
            </span>
         <Toggle
            defaultChecked={showGallery}
            onChange={toggleGallery}
            icons={false}
            about='tesst'
            />
            </div>                                                                    
    
   
    {/*end::Action*/}
  </div>
  {/*begin::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body p-9">
    {/*begin::Row*/}
    {intervention.intervention_fields.map(field => (
    <div key={field.id} className="row mb-7">
    {/*begin::Label*/}
    <label className="col-lg-2 fw-semibold text-muted">{field.field.name}</label>
    {/*end::Label*/}
    {/*begin::Col*/}
    <div className="col-lg-8">
        {
            (() => {
                switch (field.field.field_type_id) {
                    case 1:
                    case 5:
                    case 6:
                    case 3:
                      return <span className="fw-bold fs-6 text-gray-800"><input type="text" value={field.value} disabled /></span>;
                    case 8:
                    case 9:
                        return <span className="fw-bold fs-6 text-gray-800"><input type="text" value={field.value} disabled /></span>;
                    case 2:
                        return (
                            <>
                                {field.value && (
                                    <Zoom>
                                                <img srcSet={ASSETSURL+field.value}  width={"20px"} />
                                                    <CiImageOn  srcSet={ASSETSURL+field.value} color='black'  size={25} onClick={() => handleImageClick(ASSETSURL + field.value)} />
                                    </Zoom>

                                )}
                            </>
                        );
                    case 10:
                        return <span className="fw-bold fs-6 text-gray-800"><input type="checkbox" checked={field.value} disabled /></span>;
                    // Add more cases as needed
                    default:
                        return null;
                }
            })()
        }
    </div>
    {/*end::Col*/}
</div>
))}
      {showGallery && <ImageGallery items={imageGalleryImages} />}
                                            


    {/*end::Row*/}
    
   
  </div>
  {/*end::Card body*/}
</div>

        {/*end::Card*/}
      
      </div>
      {/*end::Content*/}
      {/*begin::Sidebar*/}
      <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
        {/*begin::Card*/}
        <div
          className="card card-flush mb-0"
          data-kt-sticky="true"
          data-kt-sticky-name="subscription-summary"
          data-kt-sticky-offset="{default: false, lg: '200px'}"
          data-kt-sticky-width="{lg: '250px', xl: '300px'}"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="150px"
          data-kt-sticky-animation="false"
          data-kt-sticky-zindex={95}
        >
          {/*begin::Card header*/}
          <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
              <h2>Sommaire</h2>
            </div>
            {/*end::Card title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::More options*/}
              <button
                className="btn btn-sm btn-light btn-icon"
         
              >
                <i className="ki-duotone ki-dots-square fs-3">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                </i>
              </button>
             
              {/*end::More options*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0 fs-6">
            {/*begin::Section*/}
           
            {/*begin::Seperator*/}
            <div className="separator separator-dashed mb-7" />
            {/*end::Seperator*/}
            {/*begin::Section*/}
            <div className="mb-7">
              {/*begin::Title*/}
              <h5 className="mb-4">A propos de l'intervention</h5>
              {/*end::Title*/}
              {/*begin::Details*/}
              <div className="mb-0">
                {/*begin::Price*/}
                <span className="fw-semibold text-gray-600">
                  Status : 
                </span>
                {/*end::Price*/}
                {/*begin::Plan*/}
                <span
    className={`badge ${intervention.sold_at ? 'badge-light-danger' :
                  intervention.started_at ? 'badge-light-warning' :
                  intervention.downloaded_at ? 'badge-light-info' :
                  'badge-light-success'} me-2`}
>
    {intervention.sold_at ? (
        <span>Réalisée</span>
    ) : intervention.started_at ? (
        <span>Démarrée</span>
    ) : intervention.downloaded_at ? (
        <span>Synchronisée</span>
    ) : (
        <span>Planifié</span>
    )}
</span>


                {/*end::Plan*/}
              </div>
              {/*end::Details*/}
            </div>
            {/*end::Section*/}
          
            <div className="separator separator-dashed mb-7" />
            {/*end::Seperator*/}
            {/*begin::Section*/}
            <div className="mb-10">
              {/*begin::Title*/}
              {/*end::Title*/}
              {/*begin::Details*/}
              <div className="mb-10">
    {/*begin::Title*/}
    <h5 className="mb-4">Référentiel </h5>
    {/*end::Title*/}
    {/*begin::Details*/}
    {parentHierarchy.length > 0 ? (
    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2">
        <thead>
            <tr>
                {/* <th className="text-gray-500">Nom</th> */}
            </tr>
        </thead>
        <tbody>
        {parentHierarchy.length > 0 ? (
    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2">
        <thead>
            <tr>
                {/* <th className="text-gray-500">Nom</th> */}
            </tr>
        </thead>
        <tbody>
            {parentHierarchy.map((parent, index) => (
                <tr key={index}>
                    {parent.valeur && (
                        <td
                            className="text-gray-800"
                            title={`Référentiel ID: ${parent.referentiel_id}\nCréé le: ${parent.created_at}\nMis à jour le: ${parent.updated_at}\nAdresse: ${parent.adresse}\nVille: ${parent.ville}\nCode Postal: ${parent.code_postal}\nPays: ${parent.pays}\nCoordonnées: ${parent.coordonnees}\nDescription: ${parent.description}`}
                        >
                            {parent.valeur}
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    </table>
) : (
    <p className="text-gray-800">Aucun parent disponible.</p>
)}

        </tbody>
    </table>
) : (
    <p className="text-gray-800">Aucun parent disponible.</p>
)}

    {/*end::Details*/}
</div>

              {/*end::Details*/}
            </div>
            {/*end::Section*/}
          
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Card*/}{" "}
      </div>
      {/*end::Sidebar*/}
    </div>
    {/*end::Layout*/}{" "}
  </div>
  {/*end::Post*/}
</div>

      {/*end::Container */}
      {/*begin::Footer */}
     <Footer></Footer>
      {/*end::Footer */}
    </div>
    {/*end::Wrapper */}
  </div>
  {/*end::Page */}
</div>
</div >

           </>
    );
}

export default InterventionDetail;
