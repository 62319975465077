import React, { useState, useEffect } from "react";
import axios from "axios";
import { TbCircleNumber1 } from "react-icons/tb";
import { Tooltip } from 'react-tooltip'
import {format, formatDistanceToNow, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { VscReferences } from "react-icons/vsc";

import 'react-tooltip/dist/react-tooltip.css'
import { GiPlainCircle } from "react-icons/gi";
import { CiRead } from "react-icons/ci";
import styles from './styles/tooltip.module.css'; // Import CSS module styles
import Zoom from 'react-medium-image-zoom'
function InterventionsTab() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [interventions, setInterventions] = useState([]);
  const [isLoadingInterventions, setIsLoadingInterventions] = useState(true); // State to manage loading status
  const ASSETSURL = process.env.REACT_APP_BACKEND_ASSETS;
  const navigate = useNavigate();
  const displayValeurs = (valeurReferentiel) => {
    const valeurs = [];
    
    let current = valeurReferentiel;
    while (current) {
      const tooltipId = `tooltip-valeur-${current.id}`;
      valeurs.push(
        <span key={current.id} data-tooltip-id={tooltipId}>
          {current.valeur}
          <Tooltip id={tooltipId} style={{zIndex:999999999999999}}>
            <div>
              <strong>{current.valeur}</strong>
              
              <br />
           <ul>
 
  {current.valeur && (
    <li>
      <span>Valeur: {current.valeur}</span>
    </li>
  )}
 
  {current.created_at && (
    <li>
      <span>Créé le: {current.created_at}</span>
    </li>
  )}
 
  {current.contact && (
    <li>
      <span>Contact: {current.contact}</span>
    </li>
  )}
  {current.adresse && (
    <li>
      <span>Adresse: {current.adresse}</span>
    </li>
  )}
  {current.code_postal && (
    <li>
      <span>Code Postal: {current.code_postal}</span>
    </li>
  )}
  {current.ville && (
    <li>
      <span>Ville: {current.ville}</span>
    </li>
  )}
  {current.pays && (
    <li>
      <span>Pays: {current.pays}</span>
    </li>
  )}
  {current.coordonnees && (
    <li>
      <span>Coordonnées: {current.coordonnees}</span>
    </li>
  )}
  {current.description && (
    <li>
      <span>Description: {current.description}</span>
    </li>
  )}
  {current.infos_site && (
    <li>
      <span>Infos Site: {current.infos_site}</span>
    </li>
  )}
  {current.latitude_longitude && (
    <li>
      <span>Latitude/Longitude: {current.latitude_longitude}</span>
    </li>
  )}
  {current.site && (
    <li>
      <span>Site: {current.site}</span>
    </li>
  )}
  {current.statut && (
    <li>
      <span>Statut: {current.statut}</span>
    </li>
  )}
</ul>


              
              {/* Add more fields from current if needed */}
            </div>
          </Tooltip>
        </span>
      );
      current = current.parent;
    }
  
    return valeurs.reduce((acc, curr) => [acc, ' > ', curr]);
  };

  
  const columns = [
    {
      name: '',
      selector: row => {
        const color = row.status === 'Réalisée' 
          ? '#ff0000' // Red
          : row.status === 'Démarrée'
          ? '#ffa500' // Orange
          : row.status === 'Synchronisée'
          ? '#00bfff' // Light Blue
          : '#32cd32'; // Green
    
        return <GiPlainCircle color={color} />;
      },
      width: '50px',
    },    
    {
      name: '',
      selector: row => {
        const getFirstAndLastValeur = (valeurReferentiel) => {
          const valeurs = [];
          let current = valeurReferentiel;
    
          // Parcours des parents jusqu'à la racine
          while (current) {
            valeurs.unshift(current.valeur);  // Ajoute la valeur au début
            current = current.parent;
          }
    
          // Si plus de 2 valeurs, affiche la première et la dernière avec '...'
          if (valeurs.length > 2) {
            return `${valeurs[0]} > ... > ${valeurs[valeurs.length - 1]}`;
          } else if (valeurs.length === 2) {
            // Si seulement 2 valeurs, retourne première et dernière sans '...'
            return `${valeurs[0]} > ${valeurs[1]}`;
          } else {
            return valeurs[0];  // Si une seule valeur, retourne simplement cette valeur
          }
        };
    
        // Vérifie si la donnée `valeur_referentiel` existe
        const valeurHierarchy = row.valeur_referentiel 
          ? getFirstAndLastValeur(row.valeur_referentiel) 
          : '';
    
        return (
          <>
            <a
              href="#"
              className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6"
              data-tooltip-id={`tooltip-${row.id}`} // Set data-for with tooltip ID
            >
              {valeurHierarchy}  {/* Affiche la première et la dernière valeur avec '... > ' */}
            </a>
            <span className="text-muted fw-semibold d-block">
              {row.intervention_type?.name}
            </span>
          </>
        );
      },
      // minWidth: '200px',
      width:"340px"
    }
    
    
,    
    {
      name: '',
      selector: row => row.user ? row.user.nom : '',

      minWidth: '140px',
      right: true,
    },
    {
      name: '',
      selector: row => {
        // Debugging: Log the entire row object and fields
        console.log('Row data:', row);
    
        const downloadedAt = row.downloaded_at;
        const soldAt = row.sold_at;
        const startedAt = row.started_at;
    
        console.log('downloaded_at:', downloadedAt);
        console.log('sold_at:', soldAt);
        console.log('started_at:', startedAt);
    
        let status;
    
        if (soldAt) {
          status = 'Réalisée'; // If 'sold_at' is not null
        } else if (startedAt) {
          status = 'Démarrée'; // If 'started_at' is not null
        } else if (downloadedAt) {
          status = 'Synchronisée'; // If 'downloaded_at' is not null
        } else {
          status = 'Planifié'; // If none of the above fields are set
        }
    
        console.log('Assigned status:', status);
       
        return (
          <span className={`badge ${status === 'Réalisée' ? 'badge-light-danger' : status === 'Démarrée' ? 'badge-light-warning' : status === 'Synchronisée' ? 'badge-light-info' : 'badge-light-success'}`}>
            {status}
          </span>
        );
      },
      width: '115px',
      right: true,
    },     
    {
      name: '',
      selector: row => (
        <button
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
          onClick={() => navigate(`/interventiondetail/${row.id}`)}
        >
          <i className="ki-duotone ki-arrow-right fs-2">
            <CiRead />
          </i>
        </button>
      ),
      width: '70px',
      right: true,
    },
  ];

  useEffect(() => {
    const fetchInterventions = async () => {
      try {
        const response = await axios.get(`${apiHost}/interventionsFront`);
        setInterventions(response.data.data);
        console.log("the response object",response.data.data) // Assuming the API returns an array of interventions
        setIsLoadingInterventions(false); // Set isLoading to false after data is fetched
      } catch (error) {
        console.error('Error fetching interventions:', error);
        setIsLoadingInterventions(false); // Set isLoading to false on error as well
      }
    };

    fetchInterventions();
  }, []);
  const data = interventions.map(intervention => ({
    id: intervention.id,
    description: intervention.description,
    user :intervention.user,
    downloaded_at: intervention.downloaded_at,
    started_at: intervention.started_at,
    sold_at: intervention.sold_at,
    intervention_type:intervention.intervention_type,
    valeur_referentiel:intervention.valeur_referentiel
  }));
  return (
    <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1" style={{maxHeight:"89vh"}}>
      <DataTable
      noHeader
      columns={columns}
      data={isLoadingInterventions ? [] : data}
      pagination={true}
      progressPending={isLoadingInterventions}
      progressComponent={<div className="text-center">Loading...</div>}
      noDataComponent="No data available"
      resizableColumns={true}
    />

      {/* Tooltips */}
      {interventions.map(intervention => (
        
        <Tooltip className="extra" opacity={1}    style={{ backgroundColor: "white",zIndex:999,padding:"0" ,maxHeight:'480px' ,width:"520px" ,overflowY:'scroll' ,pointerEvents:'auto'  }} border="1px solid red" 
        closeEvents={[]}  events={['click']}// Disable auto-close events
        openOnClick key={`tooltip-${intervention.id}`} id={`tooltip-${intervention.id}`} place="top" effect="solid">
    <div className="card ">
  {/*begin::Header*/}
  <div className={`card-header border-0 ${styles.fixedHeader}`}>
    <h3 className="card-title align-items-start flex-column">
      <span className="card-label fw-bold text-gray-900">
        {intervention.description}
      </span>
      
    </h3>
    {/*begin::Toolbar*/}
    <div className="card-toolbar">
      {/*begin::Menu*/}
      {/* <span class="badge badge-light-warning">En attente client</span> */}
      <span className="text-muted mt-1 fw-semibold fs-7">
      {format(new Date(intervention.created_at), 'MM/dd/yyyy')}  ({`Il y a ${formatDistanceToNow(parseISO(intervention.created_at), { locale: fr })}`})

      </span>
      {/*end::Menu*/}
    </div>
    {/*end::Toolbar*/}
  </div>
  {/*end::Header*/}
  {/*begin::Body*/}
  <div  style={{marginLeft:"5px"}}>
  <p>JH83+QXQ, Aïn Harrouda, Morocco</p>
  <table className="mb-4" style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
  <tr>
    <td style={{ textAlign:"center", border: '1px solid black', padding: '8px' }}>0 km/h</td>
    <td style={{textAlign:"center", border: '1px solid black', padding: '8px' }}>x</td>
    <td style={{ textAlign:"center",border: '1px solid black', padding: '8px' }}><span
    className={`badge ${intervention.sold_at ? 'badge-light-danger' :
                  intervention.started_at ? 'badge-light-warning' :
                  intervention.downloaded_at ? 'badge-light-info' :
                  'badge-light-success'} me-2`}
>
    {intervention.sold_at ? (
        <span>Réalisée</span>
    ) : intervention.started_at ? (
        <span>Démarrée</span>
    ) : intervention.downloaded_at ? (
        <span>Synchronisée</span>
    ) : (
        <span>Planifié</span>
    )}
</span></td>
<td style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
      <VscReferences /> {displayValeurs(intervention.valeur_referentiel)}
    </td>
  </tr>
</table>


  <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
    
  <tr><th style={{background:"#f6f6f6" , fontWeight:400 ,padding:"7px 0 7px 10px"}}  class="td" colspan="2" dir="auto">Formulaire ( {intervention.form.name }):</th></tr>
  {intervention.intervention_fields.map(field => (
    <tr key={field.field.name}>
      <td style={{ border: '1px solid black', padding: '8px' }}>{field.field.name}</td>
      <td style={{ border: '1px solid black', padding: '8px' }}>
        {field.value !== null && field.field.fieldtype.name === 'image' ? (
          <Zoom style={{zIndex:99999999999999999999999999999}}>
            <img src={ASSETSURL + field.value} alt={field.field.name} style={{ maxWidth: '100px' }} />
          </Zoom>
        ) : (
          field.value
        )}
      </td>
    </tr>
  ))}
</table>
</div>
  
  
  
  </div>
  {/*end::Body*/}



          
        </Tooltip>
      ))}
    </div>
  );
}

export default InterventionsTab;
