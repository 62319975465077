import React, { useState } from 'react';
import Modal from 'react-modal';
import DualListBox from 'react-dual-listbox';
import { FaCog } from 'react-icons/fa'; // Import an icon from react-icons

Modal.setAppElement('#root'); // Set the app element for accessibility

function ColumnSelectionModal({ columns, selectedColumns, handleColumnChange }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(columns.map(col => ({ value: col.name, label: col.name })));
  console.log('availableOptions',availableOptions);
  console.log('selectedColumns',selectedColumns);
  const [selectedOptions, setSelectedOptions] = useState(selectedColumns.map(col => ({ value: col, label: col })));
  console.log('selectedOptions',selectedOptions);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleChange = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    handleColumnChange(newSelectedOptions);
  };

  return (
    <div>
      {/* Button to open the modal */}
      <button onClick={openModal} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        <FaCog size={24} />
      </button>

      {/* Modal Component */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Columns"
        style={{
            content: {
              width: '600px',  // Adjust the width as needed
              height: '310px',  // Adjust the height to fit content
              margin: 'auto',
              padding: '20px',
              borderRadius: '8px',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
      >
        <button onClick={closeModal} style={{ float: 'right', border: 'none', background: 'none', cursor: 'pointer' }}>
          &times;
        </button>
        <div className="column-selection mt-4">
        <h2>Sélection des colonnes à afficher</h2>
        <hr></hr>
        <DualListBox
            options={availableOptions}
            selected={selectedOptions}
            onChange={handleChange}
           
        />
        <div className=' mt-2'>

          <p>Ces paramètres vous permettent de configurer la vue en personnalisant les colonnes que vous souhaitez afficher .</p>
        </div>
        </div>
      </Modal>
    </div>
  );
}

export default ColumnSelectionModal;
