import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Button, Modal, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { HiDotsHorizontal } from "react-icons/hi";
function UserActivitiesDetail() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserActivities = async () => {
      await axios
        .get(`${apiHost}/useractivities/${id}`)
        .then((response) => {
          setForms(response.data);
        })
        .catch((error) => {
          console.error(error);
          setError("Failed to fetch data from the API.");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchUserActivities();
  }, [id]);
  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
    },
    {
      name: "Activitée",
      selector: (row) => row.activity_name,
    },
  
    {
      name: "Intervention lié",
      selector: (row) => row.intervention_id ?  row.intervention_id : <HiDotsHorizontal /> ,
      sortable: true,
    },
    {
      name: "Début",
      selector: (row) => {
        if (row.debut) {
          const debutDate = new Date(row.debut);
          return debutDate.toLocaleDateString(); // Adjust date format as needed
        }
        return "N/A"; // Handle null or undefined debut
      },
    },
    {
      name: "Fin",
      selector: (row) => {
        if (row.Fin) {
          const finDate = new Date(row.Fin);
          return finDate.toLocaleDateString(); // Adjust date format as needed
        }
        return "N/A"; // Handle null or undefined Fin
      },
    },
  ];

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >tesst</p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche ..."
                      // onChange={handleInputChange}
                    />
                  </div>
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button
                      onClick={() =>navigate(`/techniciens/create`)}
                      className="btn btn-primary"
                    >
                      Nouveau
                    </button>
                  </div>
                  <div className="column-selection mt-4">
                    {/* <ColumnSelectionModal
        columns={columns}
        selectedColumns={selectedColumns}
        handleColumnChange={handleColumnChange}
      /> */}
                  </div>
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Suivi des activités (Activités)
                  </h1>
                  {/*begin::Card body */}
                  <div className="card-body pt-0">
                  {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/assets/loading.svg" alt="Loading" />
                </div>
              ) : (
                <>
                  {error ? (
                    "Data not Found"
                  ) : (
                    <DataTable
                      columns={columns}
                      data={forms}
                      pagination
                      highlightOnHover={true}
                      paginationPerPage={10} // Set the number of rows per page
                      customStyles={{
                        headCells: {
                          style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            color: "black",
                          },
                        },
                      }}
                    />
                  )}
                </>
              )}
                  </div>

                  {/*end::Card body */}
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
      
    </div>
  );
}

export default UserActivitiesDetail;
